import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Image } from 'semantic-ui-react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useTermsOfService from 'content-queries/terms-of-service'
import Layout from 'mx/layout'
import { Section } from 'mx/section'

const TermsOfService = () => {
  const termsOfService = useTermsOfService()
  return (
    <Layout>
      <Section>
        <div tw="prose mx-6 md:mx-auto my-20">
          {renderRichText(termsOfService.content, {
            renderNode: {
              [BLOCKS.EMBEDDED_ASSET]: ({
                data: {
                  target: { fields },
                },
              }) => {
                return fields ? (
                  <Image
                    src={fields.file['en-US'].url}
                    style={{ height: 'auto' }}
                  />
                ) : null
              },
            },
          })}
        </div>
      </Section>
    </Layout>
  )
}

export default TermsOfService

export const Head = () => (
  <SEO
    title="Terms Of Service"
    description="Learn more about FORM Kitchens terms of service."
    path="/terms-of-service"
  />
)

import { useStaticQuery, graphql } from 'gatsby'

const TERMS_OF_SERVICE_QUERY = graphql`
  query {
    content: contentfulArticle(
      contentful_id: { eq: "6nFhe9i1H19APbkUUobNdM" }
    ) {
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      tags
    }
  }
`

const useTermsOfService = () => {
  const { content } = useStaticQuery(TERMS_OF_SERVICE_QUERY)
  return content
}

export default useTermsOfService
